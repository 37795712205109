@import "./assets/styles/css/doNotModify/custom-theme.css";
@import "./assets/styles/sass/variables";

body {
  margin: 0;
  font-weight: 400;
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.flex {
  display: flex;
}

.flex-1 {
  flex: 1;
}

.inline {
  display: inline-block !important;
}

.block {
  display: block !important;
}

.color-primary {
  color: var(--primary-color) !important;
}

.color-secondary {
  color: var(--secondary-color) !important;
}

.color-danger {
  color: var(--error-color) !important;
}
.color-success {
  color: var(--success-color) !important;
}
.color-warning {
  color: var(--warning-color) !important;
}

.search-secondary.ant-input-search {
  border: 2px solid var(--secondary-color);
  border-radius: 10px;
  width: 291px;
}

.search-secondary .ant-input-group-addon button {
  background-color: var(--secondary-color);
  font-size: 16px;
  border-radius: 6px;
}

.search-secondary.ant-input-search-enter-button input {
  border-radius: 10px;
}
.search-secondary.ant-input-search-enter-button input:focus,
.search-secondary.ant-input-search-enter-button input:hover {
  border-color: transparent !important;
}

.m-l-5 {
  margin-left: 5px;
}
.m-l-10 {
  margin-left: 10px;
}
.m-l-15 {
  margin-left: 15px;
}
.m-l-20 {
  margin-left: 20px;
}
.m-r-5 {
  margin-right: 5px;
}
.m-r-10 {
  margin-right: 10px;
}
.m-r-15 {
  margin-right: 15px;
}
.m-r-20 {
  margin-right: 20px;
}
.m-t-5 {
  margin-top: 5px;
}
.m-t-10 {
  margin-top: 10px;
}
.m-t-15 {
  margin-top: 15px;
}
.m-t-20 {
  margin-top: 20px;
}
.m-b-0 {
  margin-bottom: 0px !important;
}
.m-b-5 {
  margin-bottom: 5px;
}
.m-b-10 {
  margin-bottom: 10px;
}
.m-b-15 {
  margin-bottom: 15px;
}
.m-b-20 {
  margin-bottom: 20px;
}

.text-center {
  text-align: center;
}
.small {
  font-size: 12px;
}

.font-16 {
  font-size: 16px;
}


.search-secondary {
  .ant-input-group-addon {
    padding: 0px;
  }
  .ant-input {
    border: 0px solid;
    top: 2px;
  }
}

.pointer {
  cursor: pointer !important;
}

.pull-right {
  float: right !important;
}

.pull-left {
  float: left !important;
}

.default-table-styles {
  .ant-table-filter-column-title {
    color: var(--primary-color);
    font-weight: 600;
    font-size: 16px;
  }
}

.hover-link:hover {
  color: var(--link-color) !important;
  text-decoration: underline;
  cursor: pointer;
}